@import '../styles/ms';

%menu-border-bottom{
    border-bottom:5px solid #1b1c1d;
}

div.ui.menu.navbar {
    border-radius:0px;
    margin:0;
    min-height:$navbar_height;
    a.navbarLink{
        padding:15px;
        outline:0;
        font-size:20px;
        @extend %menu-border-bottom;
        padding:0px;
        &.active,&:hover{
            @extend %menu-border-bottom;
            border-bottom-color: $ms_green;
         }
        div.item {
            @media only screen and (max-width: $mobile_width) {
                padding: 20px 5px 20px 5px;
                font-size:20px;
            }
        }
    }
    a.docLink{
        opacity: 1;
        &.active,&.hover{
            color:#140f45;
        }
    }
    div.item.logo{
        padding:0;
        img{
            margin:10px;
            height:auto;
            width:80px;
        }
        @media only screen and (max-width: $mobile_width) {
            display: none;
        }
    }
    div.item.navRight{
        padding:10px;
        a.ui.label{
            font-size:20px;
            @media only screen and (max-width: $mobile_width) {
                span.loginText{
                    display:none;
                }
                i{
                    margin:0;
                }
            }
            
        }
        div.ui.dropdown{
            height: 30px;
            div.text{
                position: relative;
                bottom:3px;
            }
            @media only screen and (max-width: $mobile_width) {
                display:none;
            }
        }
    }
}