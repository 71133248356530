@import '../styles/ms';

div.modalError{
    div.content{
        max-height:300px;
        overflow:auto;
        pre{
            white-space: pre-wrap;
        }
    }
}