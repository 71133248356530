@import "../styles/ms";

div.ui.card.vehicle {
  margin-bottom: 5px;
  //background-color: red;
  .card.blue {
    background-color: rgba(33, 133, 208, 0.2);
  }
  div.stats {
    padding-left: 0;
    padding-right: 0;
    .statistic.brand,
    .statistic.model,
    .statistic.year {
      @extend %vehicle-statistic;
      margin: 0;
      margin-left: 10px;
      @media screen and (max-width: 1600px) {
        display: none;
      }
    }
    img.tiny.image {
      height: 80px;
    }
  }

  div.servicenear {
    background-color: yellow;
  }

  div.servicedue {
    background-color: rgb(223, 63, 63);
    .list {
      color: black;
    }
  }

  div.column.vehicleName {
    div.header.serial {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

.buttonrow {
  align-items: center;
  margin-left: 20px;
}

.label {
  font-weight: bold;
  font-size: larger;
}

.grid {
  margin-left: 20px;
}

.axel_row {
  align-items: center;
}
