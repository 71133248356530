@import 'ms';
html,
body {
    @extend %flex1;
    div.ui.form{
        div.formFieldError{
            color: $ms_red;
            font-weight:bold;
        }
    }
    .customhidden{
        display: none !important;
    }
    a.LinkItem{
        &:hover{
            color:$ms_green;
        }
    }
    //Create success state
    .ui.form .field.success label {
        color: #308330;
    }
    .ui.form .field.success div.ui.icon.input i {
        color: #038330
    }
    .ui.form .field.success input,
    .ui.form .field.success textarea {
        background: #f4faf4 none repeat scroll 0 0;
        border-color: #a3c293;
        border-radius: 0.285714rem;
        box-shadow: none;
        color: #308330;
    }
    /* Success Placeholder */
    .ui.form .success ::-webkit-input-placeholder {
        color: #5e9e5e;
    }
    .ui.form .success ::-ms-input-placeholder {
        color: #5e9e5e;
    }
    .ui.form .success ::-moz-placeholder {
        color: #5e9e5e;
    }
    .ui.form .success :focus::-webkit-input-placeholder {
        color: #558e55;
    }
    .ui.form .success :focus::-ms-input-placeholder {
        color: #558e55;
    }
    .ui.form .success :focus::-moz-placeholder {
        color: #558e55;
    }
    //FORM VALIDATION
    //In modal
    div.ui.modal{
        div.ui.form {
            div.ui.pointing.left {
                position:absolute !important;
                min-width: 100px;
            }
        }
    }
}