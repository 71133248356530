//Millisecond related styles
$ms_green:#84C326;
$ms_red:#9f3a38;
$ms_black:black;

$semantic_green:#21ba45;
$semantic_red:#db2828;
$semantic_orange:#eabd8f;
$navbar_height:52px;

$mobile_width:840px;

%flex1{
    display:flex;
    flex:1;
    flex-flow: column;
    overflow: auto;
}

%card-header-title{
    color:$ms_black;
    opacity:0.85;
    font-size:1.5em;
    font-weight:700;
    line-height: 1.5em;
    float:left;
}

%card-header-button{
    float:right;
}

%ms-link{
    &:hover{
        color:$ms_green;
    }
    color:black;
}

%text-ellipsis{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

%vehicle-statistic{
    div.value{
        font-size:1.4rem !important;
    }
    div.label{
        font-size:0.8em;
        color:grey;
    }
}