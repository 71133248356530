@import '../styles/ms';

h1{
    color:black;
   }

.main{
    margin-left: 0px;
    margin-top: 0px;
}
