@import '../styles/ms';

//This makes sure that the main container uses
//All the width/height available
#root{
  @extend %flex1;
  div.app-wrapper{
    @extend %flex1;
    /* Semantic UI overrides */
    >div.ui.grid.container.main{
      width:100% !important;
      margin:0 !important;
      @extend %flex1;
      flex-direction: row;
      >div.column.wide{
        @extend %flex1;
        justify-content: space-between;
        display:flex !important;
        flex:unset;
      }
    }
  }
}