@import '../styles/ms';

h1{
    color:black;
   }

.main{
    margin-left: 10px;
    margin-top: 10px;
}
