@import 'ms';
@import './global-styles.scss';

/*Semantic override*/
//msgreen button
button.ui.green.button,
button.ui.green.button:active,
button.ui.green.button:focus{
    background-color: rgba($ms_green,0.85);
    color: #fff;
    text-shadow: none;
    background-image: none;
    &:hover,&:active{
        background-color:rgba($ms_green,1);
    }
}