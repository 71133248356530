@import '../styles/ms';
div.ui.main{
    div.ui.fluid.card{
        margin-bottom: 10px;
    }
    section.csvdrop{
        //width:98% !important;
        /*margin:15px;
        border: 3px dashed rgba(0,0,0,0.5);
        height: 200px;*/
        @extend %flex1;
        div.dropzone{
            margin:15px;
            @extend %flex1;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 20px;
            border-width: 2px;
            border-radius: 2px;
            border-color: #eeeeee;
            border-style: dashed;
            background-color: #fafafa;
            color: rgba(0,0,0,0.8);
            font-size:30px;
            outline: none;
            transition: border .24s ease-in-out;
            
        }
    }
    
}
