@import '../styles/ms';

div.ui.inputrevenueform{
    div.field{
        div.ui.input.small{
            input{
                width:100%;
            }
        }
    }
}