@import "../styles/ms";

.inputservice {
  margin-left: 20px;
  margin-top: 10px;
  margin-right: 20px;
}
.interval_input {
  margin-bottom: 15px !important;
}
